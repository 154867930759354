input:focus, select:focus, textarea:focus, button:focus { outline: none }

.buttonTransparent {
  border: none;
  color: transparent;
  box-shadow: transparent;
  width: 10vw;
  height: 10vh;
}

.bgUnderEnvelope {
  animation: bgSalmon 5s ease-in-out infinite;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
@keyframes bgSalmon {
  0% {background-color: salmon;}
  25% {background-color: rosybrown;}
  50% {background-color: peachpuff;}
  75% {background-color: indianred;}
  100% {background-color: salmon;}
}

.animatedHeart {
  animation: resizeHeart 1s infinite;
  position: center;
  width: 30vh;
  height: 30vh;
  left: 50vw;
  top: 35vh;
}
@keyframes resizeHeart {
  from {transform: scale(1, 1);}
  to {transform: scale(1.2, 1.2);}
}

.startOpenEnvelope {
  animation: openEnvelope 7s forwards;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
@keyframes openEnvelope {
  from {transform-origin: 0 0}
  to {transform: scale(1, -1); transform-origin: 0 -50vh}
}

.cardFromEnvelope {
  animation: cardRemove 7s 3s forwards;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
@keyframes cardRemove {
  0% {width: 10vw; height: 10vh; transform: rotate(45deg); left: 50vw; top: 40vh; opacity: 50%;}
  10% {width: 10vw; height: 10vh; transform: rotate(45deg); left: 50vw; top: 20vh; opacity: 50%;}
  100% {width: 100vw; height: 100vh; transform: rotate(720deg); left: 0; top: 0; opacity: 100%;}
}

.textPopup {
  width: 50vw;
  height: 20vh;
  left: 25vw;
  top: 10vh; ;
}

.removeEnvelope { animation: fadeEnvelope 3s 3s forwards;}
@keyframes fadeEnvelope {
  from {opacity: 100%;} to {opacity: 0;}
}

.invisible {
  opacity: 0;
  visibility: hidden;
  transform: scale(0, 0);
  width: 0;
  height: 0;
}

.withDelay {
  animation: showTextWithDelay 3s 10s forwards;
  color: transparent;
  position: absolute;
  font-family: Calibri, Monaco, monospace;
  width: 50vw;
  height: 0;
  left: 25vw;
  top: -50vh;
}
@keyframes showTextWithDelay {
  from {width: 80vw; height: 0; left: 10vw; top: -50vh; opacity: 0; color: transparent;}
  to {width: 80vw; height: 20vh; left: 10vw; top: 40vh; opacity: 100%; color: mistyrose;}
}

.volkiButton {
  position: relative;
  top: 30%;
  left: 30%;
  width: 30%;
}
.volkiButton:hover {
  animation: tremble 100ms infinite;
  transform: scale(1.1, 1.1);
}
@keyframes tremble {
  from { transform: rotate(-10deg) }
  to { transform: rotate(10deg) }
}

.fading { animation: fadeout 3s;}
@keyframes fadeout {
  from { opacity: 100% }
  to { opacity: 0 }
}

@keyframes fadein {
  from { opacity: 0 }
  to { opacity: 100% }
}

.backgroundWe {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  animation: weSwap 20s ease-in-out forwards;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}
@keyframes weSwap {
  0% { background-image: url("./assets/img/we1.jpg"); opacity: 0 ; }
  10% { background-image: url("./assets/img/we1.jpg"); opacity: 1 ; }
  50% { background-image: url("./assets/img/we2.png"); opacity: 1 ; }
  100% { background-image: url("./assets/img/we3.jpg"); opacity: 1 ;  }
}

.textBeforeSwap {
  animation: fadeout 10s ease-in-out forwards;
  font-family: Calibri, Monaco, monospace;
  font-size: 200%;
  padding-top: 10vh;
  position: fixed;
  color: blue;
  text-shadow: 0 0 1vw white;
  align-content: center;
  width: 100vw;
}

.textSwap {
  animation: fadeinNew 30s ease forwards;
  font-family: Tangerine, sans-serif;
  font-size: 300%;
  text-shadow: 0 0 1vw darkblue;
  padding-top: 85vh;
  position: fixed;
  color: orangered;
  font-weight: bold;
  align-content: center;
  width: 100vw;
}
@keyframes fadeinNew {
  0% { opacity: 0 }
  50% { opacity: 0 }
  100% { opacity: 1 }
}

.back {
  opacity: 0.1;
  animation: fadeinBack 30s;
  bottom: 35vh;
  left: 5vw;
  position: fixed;
  color: orangered;
  width: 10vw;
  box-sizing: border-box;
}
.back:hover {
  opacity: 0.7;
  box-shadow: 0 0 1vw lightskyblue;
}
@keyframes fadeinBack {
  0% { opacity: 0 }
  80% { opacity: 0 }
  100% { opacity: 0.1 }
}