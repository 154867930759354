body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus, select:focus, textarea:focus, button:focus { outline: none }

.buttonTransparent {
  border: none;
  color: transparent;
  box-shadow: transparent;
  width: 10vw;
  height: 10vh;
}

.bgUnderEnvelope {
  -webkit-animation: bgSalmon 5s ease-in-out infinite;
          animation: bgSalmon 5s ease-in-out infinite;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
@-webkit-keyframes bgSalmon {
  0% {background-color: salmon;}
  25% {background-color: rosybrown;}
  50% {background-color: peachpuff;}
  75% {background-color: indianred;}
  100% {background-color: salmon;}
}
@keyframes bgSalmon {
  0% {background-color: salmon;}
  25% {background-color: rosybrown;}
  50% {background-color: peachpuff;}
  75% {background-color: indianred;}
  100% {background-color: salmon;}
}

.animatedHeart {
  -webkit-animation: resizeHeart 1s infinite;
          animation: resizeHeart 1s infinite;
  position: center;
  width: 30vh;
  height: 30vh;
  left: 50vw;
  top: 35vh;
}
@-webkit-keyframes resizeHeart {
  from {-webkit-transform: scale(1, 1);transform: scale(1, 1);}
  to {-webkit-transform: scale(1.2, 1.2);transform: scale(1.2, 1.2);}
}
@keyframes resizeHeart {
  from {-webkit-transform: scale(1, 1);transform: scale(1, 1);}
  to {-webkit-transform: scale(1.2, 1.2);transform: scale(1.2, 1.2);}
}

.startOpenEnvelope {
  -webkit-animation: openEnvelope 7s forwards;
          animation: openEnvelope 7s forwards;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
@-webkit-keyframes openEnvelope {
  from {-webkit-transform-origin: 0 0;transform-origin: 0 0}
  to {-webkit-transform: scale(1, -1);transform: scale(1, -1); -webkit-transform-origin: 0 -50vh; transform-origin: 0 -50vh}
}
@keyframes openEnvelope {
  from {-webkit-transform-origin: 0 0;transform-origin: 0 0}
  to {-webkit-transform: scale(1, -1);transform: scale(1, -1); -webkit-transform-origin: 0 -50vh; transform-origin: 0 -50vh}
}

.cardFromEnvelope {
  -webkit-animation: cardRemove 7s 3s forwards;
          animation: cardRemove 7s 3s forwards;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
@-webkit-keyframes cardRemove {
  0% {width: 10vw; height: 10vh; -webkit-transform: rotate(45deg); transform: rotate(45deg); left: 50vw; top: 40vh; opacity: 50%;}
  10% {width: 10vw; height: 10vh; -webkit-transform: rotate(45deg); transform: rotate(45deg); left: 50vw; top: 20vh; opacity: 50%;}
  100% {width: 100vw; height: 100vh; -webkit-transform: rotate(720deg); transform: rotate(720deg); left: 0; top: 0; opacity: 100%;}
}
@keyframes cardRemove {
  0% {width: 10vw; height: 10vh; -webkit-transform: rotate(45deg); transform: rotate(45deg); left: 50vw; top: 40vh; opacity: 50%;}
  10% {width: 10vw; height: 10vh; -webkit-transform: rotate(45deg); transform: rotate(45deg); left: 50vw; top: 20vh; opacity: 50%;}
  100% {width: 100vw; height: 100vh; -webkit-transform: rotate(720deg); transform: rotate(720deg); left: 0; top: 0; opacity: 100%;}
}

.textPopup {
  width: 50vw;
  height: 20vh;
  left: 25vw;
  top: 10vh; ;
}

.removeEnvelope { -webkit-animation: fadeEnvelope 3s 3s forwards; animation: fadeEnvelope 3s 3s forwards;}
@-webkit-keyframes fadeEnvelope {
  from {opacity: 100%;} to {opacity: 0;}
}
@keyframes fadeEnvelope {
  from {opacity: 100%;} to {opacity: 0;}
}

.invisible {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  width: 0;
  height: 0;
}

.withDelay {
  -webkit-animation: showTextWithDelay 3s 10s forwards;
          animation: showTextWithDelay 3s 10s forwards;
  color: transparent;
  position: absolute;
  font-family: Calibri, Monaco, monospace;
  width: 50vw;
  height: 0;
  left: 25vw;
  top: -50vh;
}
@-webkit-keyframes showTextWithDelay {
  from {width: 80vw; height: 0; left: 10vw; top: -50vh; opacity: 0; color: transparent;}
  to {width: 80vw; height: 20vh; left: 10vw; top: 40vh; opacity: 100%; color: mistyrose;}
}
@keyframes showTextWithDelay {
  from {width: 80vw; height: 0; left: 10vw; top: -50vh; opacity: 0; color: transparent;}
  to {width: 80vw; height: 20vh; left: 10vw; top: 40vh; opacity: 100%; color: mistyrose;}
}

.volkiButton {
  position: relative;
  top: 30%;
  left: 30%;
  width: 30%;
}
.volkiButton:hover {
  -webkit-animation: tremble 100ms infinite;
          animation: tremble 100ms infinite;
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
}
@-webkit-keyframes tremble {
  from { -webkit-transform: rotate(-10deg); transform: rotate(-10deg) }
  to { -webkit-transform: rotate(10deg); transform: rotate(10deg) }
}
@keyframes tremble {
  from { -webkit-transform: rotate(-10deg); transform: rotate(-10deg) }
  to { -webkit-transform: rotate(10deg); transform: rotate(10deg) }
}

.fading { -webkit-animation: fadeout 3s; animation: fadeout 3s;}
@-webkit-keyframes fadeout {
  from { opacity: 100% }
  to { opacity: 0 }
}
@keyframes fadeout {
  from { opacity: 100% }
  to { opacity: 0 }
}

@-webkit-keyframes fadein {
  from { opacity: 0 }
  to { opacity: 100% }
}

@keyframes fadein {
  from { opacity: 0 }
  to { opacity: 100% }
}

.backgroundWe {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  -webkit-animation: weSwap 20s ease-in-out forwards;
          animation: weSwap 20s ease-in-out forwards;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}
@-webkit-keyframes weSwap {
  0% { background-image: url(/static/media/we1.126f3211.jpg); opacity: 0 ; }
  10% { background-image: url(/static/media/we1.126f3211.jpg); opacity: 1 ; }
  50% { background-image: url(/static/media/we2.96eecfa4.png); opacity: 1 ; }
  100% { background-image: url(/static/media/we3.74a85d56.jpg); opacity: 1 ;  }
}
@keyframes weSwap {
  0% { background-image: url(/static/media/we1.126f3211.jpg); opacity: 0 ; }
  10% { background-image: url(/static/media/we1.126f3211.jpg); opacity: 1 ; }
  50% { background-image: url(/static/media/we2.96eecfa4.png); opacity: 1 ; }
  100% { background-image: url(/static/media/we3.74a85d56.jpg); opacity: 1 ;  }
}

.textBeforeSwap {
  -webkit-animation: fadeout 10s ease-in-out forwards;
          animation: fadeout 10s ease-in-out forwards;
  font-family: Calibri, Monaco, monospace;
  font-size: 200%;
  padding-top: 10vh;
  position: fixed;
  color: blue;
  text-shadow: 0 0 1vw white;
  align-content: center;
  width: 100vw;
}

.textSwap {
  -webkit-animation: fadeinNew 30s ease forwards;
          animation: fadeinNew 30s ease forwards;
  font-family: Tangerine, sans-serif;
  font-size: 300%;
  text-shadow: 0 0 1vw darkblue;
  padding-top: 85vh;
  position: fixed;
  color: orangered;
  font-weight: bold;
  align-content: center;
  width: 100vw;
}
@-webkit-keyframes fadeinNew {
  0% { opacity: 0 }
  50% { opacity: 0 }
  100% { opacity: 1 }
}
@keyframes fadeinNew {
  0% { opacity: 0 }
  50% { opacity: 0 }
  100% { opacity: 1 }
}

.back {
  opacity: 0.1;
  -webkit-animation: fadeinBack 30s;
          animation: fadeinBack 30s;
  bottom: 35vh;
  left: 5vw;
  position: fixed;
  color: orangered;
  width: 10vw;
  box-sizing: border-box;
}
.back:hover {
  opacity: 0.7;
  box-shadow: 0 0 1vw lightskyblue;
}
@-webkit-keyframes fadeinBack {
  0% { opacity: 0 }
  80% { opacity: 0 }
  100% { opacity: 0.1 }
}
@keyframes fadeinBack {
  0% { opacity: 0 }
  80% { opacity: 0 }
  100% { opacity: 0.1 }
}
.buttonContainer {
  position: absolute;
  width: 30vw;
  top: 35vh;
  left: 35vw;
  height: 30vh;
  text-align: center; }

.text {
  font-family: Tangerine, Helvetica, sans-serif;
  font-size: 4vw;
  text-align: center;
  color: white;
  text-shadow: 0 0 1vw greenyellow; }

.positionStar {
  position: fixed;
  width: 10vw;
  top: 45vh;
  left: 45vw; }

.position1 {
  position: fixed;
  width: 15vw;
  top: 15vh;
  left: 15vw; }

.position2 {
  position: fixed;
  width: 15vw;
  top: 55vh;
  left: 10vw; }

.position3 {
  position: fixed;
  width: 15vw;
  top: 10vh;
  left: 30vw; }

.position4 {
  position: fixed;
  width: 15vw;
  top: 5vh;
  left: 45vw; }

.position5 {
  position: fixed;
  width: 15vw;
  top: 15vh;
  left: 65vw; }

.position6 {
  position: fixed;
  width: 15vw;
  top: 35vh;
  left: 80vw; }

.position7 {
  position: fixed;
  width: 15vw;
  top: 70vh;
  left: 70vw; }

.yellow {
  -webkit-animation: glittering 5s ease 1s infinite;
          animation: glittering 5s ease 1s infinite; }

.green {
  -webkit-animation: glittering 3s ease 2s infinite;
          animation: glittering 3s ease 2s infinite; }

.turquoise {
  -webkit-animation: glittering 4s ease 3s infinite;
          animation: glittering 4s ease 3s infinite; }

.orange {
  -webkit-animation: glittering 5s ease 3s infinite;
          animation: glittering 5s ease 3s infinite; }

.violet {
  -webkit-animation: glittering 4s ease 1s infinite;
          animation: glittering 4s ease 1s infinite; }

.pink {
  -webkit-animation: glittering 4s ease 1s infinite;
          animation: glittering 4s ease 1s infinite; }

.cherry {
  -webkit-animation: glittering 3s ease 3s infinite;
          animation: glittering 3s ease 3s infinite; }

@-webkit-keyframes glittering {
  from {
    opacity: 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  to {
    opacity: 90%;
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1); } }

@keyframes glittering {
  from {
    opacity: 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  to {
    opacity: 90%;
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1); } }

.starExpands {
  -webkit-animation: star 500ms 6s;
          animation: star 500ms 6s; }

@-webkit-keyframes star {
  0% {
    -webkit-transform: scale(1, 1) rotate(0deg);
            transform: scale(1, 1) rotate(0deg); }
  10% {
    -webkit-transform: scale(1.2, 1.2) rotate(90deg);
            transform: scale(1.2, 1.2) rotate(90deg); }
  50% {
    -webkit-transform: scale(0.9, 0.9) rotate(360deg);
            transform: scale(0.9, 0.9) rotate(360deg); }
  60% {
    -webkit-transform: scale(1.1, 1.1) rotate(90deg);
            transform: scale(1.1, 1.1) rotate(90deg); }
  80% {
    -webkit-transform: scale(0.9, 0.9) rotate(180deg);
            transform: scale(0.9, 0.9) rotate(180deg); }
  100% {
    -webkit-transform: scale(1, 1) rotate(0deg);
            transform: scale(1, 1) rotate(0deg); } }

@keyframes star {
  0% {
    -webkit-transform: scale(1, 1) rotate(0deg);
            transform: scale(1, 1) rotate(0deg); }
  10% {
    -webkit-transform: scale(1.2, 1.2) rotate(90deg);
            transform: scale(1.2, 1.2) rotate(90deg); }
  50% {
    -webkit-transform: scale(0.9, 0.9) rotate(360deg);
            transform: scale(0.9, 0.9) rotate(360deg); }
  60% {
    -webkit-transform: scale(1.1, 1.1) rotate(90deg);
            transform: scale(1.1, 1.1) rotate(90deg); }
  80% {
    -webkit-transform: scale(0.9, 0.9) rotate(180deg);
            transform: scale(0.9, 0.9) rotate(180deg); }
  100% {
    -webkit-transform: scale(1, 1) rotate(0deg);
            transform: scale(1, 1) rotate(0deg); } }

.heartsAnimated {
  -webkit-animation: flying 7s forwards;
          animation: flying 7s forwards;
  width: 15vw; }

@-webkit-keyframes flying {
  0% {
    -webkit-transform: rotate(0deg) translateX(0) rotate(0deg) scale(1, 1);
            transform: rotate(0deg) translateX(0) rotate(0deg) scale(1, 1); }
  100% {
    -webkit-transform: rotate(720deg) translateX(15vw) rotate(-720deg) scale(0.1, 0.1);
            transform: rotate(720deg) translateX(15vw) rotate(-720deg) scale(0.1, 0.1);
    top: 40vh;
    left: 28vw; } }

@keyframes flying {
  0% {
    -webkit-transform: rotate(0deg) translateX(0) rotate(0deg) scale(1, 1);
            transform: rotate(0deg) translateX(0) rotate(0deg) scale(1, 1); }
  100% {
    -webkit-transform: rotate(720deg) translateX(15vw) rotate(-720deg) scale(0.1, 0.1);
            transform: rotate(720deg) translateX(15vw) rotate(-720deg) scale(0.1, 0.1);
    top: 40vh;
    left: 28vw; } }

.rainbow {
  -webkit-animation: rainbowAppears 7s 4s forwards;
          animation: rainbowAppears 7s 4s forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 0; }

@-webkit-keyframes rainbowAppears {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: fixed;
    top: 100vh;
    left: 0;
    width: 0;
    height: 0; }
  30% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  40% {
    opacity: 1;
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  45% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  60% {
    opacity: 1;
    -webkit-transform: scale(15, 30);
            transform: scale(15, 30);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: fixed;
    top: 0;
    left: -5vw;
    width: 25vw;
    height: 25vh; } }

@keyframes rainbowAppears {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: fixed;
    top: 100vh;
    left: 0;
    width: 0;
    height: 0; }
  30% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  40% {
    opacity: 1;
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  45% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  60% {
    opacity: 1;
    -webkit-transform: scale(15, 30);
            transform: scale(15, 30);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: fixed;
    top: 0;
    left: -5vw;
    width: 25vw;
    height: 25vh; } }

.sun1 {
  -webkit-animation: sunPulses 8s 4s forwards;
          animation: sunPulses 8s 4s forwards;
  position: fixed;
  top: 30vw;
  left: 50vw;
  width: 0;
  height: 0; }

.sun2 {
  -webkit-animation: sunRotates 8s 4s forwards;
          animation: sunRotates 8s 4s forwards;
  position: fixed;
  top: 30vw;
  left: 50vw;
  width: 0;
  height: 0; }

@-webkit-keyframes sunRotates {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 30vw;
    left: 50vw;
    width: 0;
    height: 0; }
  10% {
    opacity: 1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  20% {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  40% {
    opacity: 1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  60% {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  70% {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1, 1);
            transform: rotate(-45deg) scale(1, 1);
    top: -15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  90% {
    opacity: 0;
    -webkit-transform: scale(3, 3);
            transform: scale(3, 3);
    top: -15vw;
    left: 0;
    width: 100vw;
    height: 100vh; }
  91% {
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; } }

@keyframes sunRotates {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 30vw;
    left: 50vw;
    width: 0;
    height: 0; }
  10% {
    opacity: 1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  20% {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  40% {
    opacity: 1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  60% {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  70% {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1, 1);
            transform: rotate(-45deg) scale(1, 1);
    top: -15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  90% {
    opacity: 0;
    -webkit-transform: scale(3, 3);
            transform: scale(3, 3);
    top: -15vw;
    left: 0;
    width: 100vw;
    height: 100vh; }
  91% {
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; } }

@-webkit-keyframes sunPulses {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 30vw;
    left: 50vw;
    width: 0;
    height: 0; }
  10% {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  40% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  45% {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  60% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  70% {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    top: -15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  90% {
    opacity: 0;
    -webkit-transform: scale(3, 3);
            transform: scale(3, 3);
    top: -15vw;
    left: 0;
    width: 100vw;
    height: 100vh; }
  91% {
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; } }

@keyframes sunPulses {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 30vw;
    left: 50vw;
    width: 0;
    height: 0; }
  10% {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  40% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  45% {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  60% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  70% {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    top: -15vw;
    left: 35vw;
    width: 30vw;
    height: 30vw; }
  90% {
    opacity: 0;
    -webkit-transform: scale(3, 3);
            transform: scale(3, 3);
    top: -15vw;
    left: 0;
    width: 100vw;
    height: 100vh; }
  91% {
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 0;
    left: 90vw;
    width: 10vw;
    height: 10vw; } }

@-webkit-keyframes smiles {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  30% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    opacity: 0.2;
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5); } }

@keyframes smiles {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  30% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    opacity: 0.2;
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5); } }

.showLove {
  top: 25vh;
  left: 25vw;
  opacity: 0;
  width: 0;
  height: 0;
  -webkit-animation: emojiSwaps 5s 2s ease-in-out forwards;
          animation: emojiSwaps 5s 2s ease-in-out forwards;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

@-webkit-keyframes emojiSwaps {
  0% {
    background-image: url(/static/media/emoji2.4ce038f2.png);
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  10% {
    background-image: url(/static/media/emoji2.4ce038f2.png);
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  30% {
    background-image: url(/static/media/emoji1.2f0decb9.png);
    opacity: 1;
    -webkit-transform: scale(1, 1) rotate(0deg);
            transform: scale(1, 1) rotate(0deg);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  99% {
    background-image: url(/static/media/emoji1.2f0decb9.png);
    opacity: 1;
    -webkit-transform: scale(0, 0) rotate(3600deg);
            transform: scale(0, 0) rotate(3600deg);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  100% {
    background-image: url(/static/media/emoji1.2f0decb9.png);
    opacity: 0.5;
    -webkit-transform: scale(0.7, 0.7) rotate(0deg);
            transform: scale(0.7, 0.7) rotate(0deg);
    top: 70vh;
    left: 0;
    width: 20vw;
    height: 20vh; } }

@keyframes emojiSwaps {
  0% {
    background-image: url(/static/media/emoji2.4ce038f2.png);
    opacity: 0;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  10% {
    background-image: url(/static/media/emoji2.4ce038f2.png);
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  30% {
    background-image: url(/static/media/emoji1.2f0decb9.png);
    opacity: 1;
    -webkit-transform: scale(1, 1) rotate(0deg);
            transform: scale(1, 1) rotate(0deg);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  99% {
    background-image: url(/static/media/emoji1.2f0decb9.png);
    opacity: 1;
    -webkit-transform: scale(0, 0) rotate(3600deg);
            transform: scale(0, 0) rotate(3600deg);
    top: 25vh;
    left: 50vw;
    width: 20vw;
    height: 20vh; }
  100% {
    background-image: url(/static/media/emoji1.2f0decb9.png);
    opacity: 0.5;
    -webkit-transform: scale(0.7, 0.7) rotate(0deg);
            transform: scale(0.7, 0.7) rotate(0deg);
    top: 70vh;
    left: 0;
    width: 20vw;
    height: 20vh; } }

.dirt {
  -webkit-animation: cleaningBG 10s 5s;
          animation: cleaningBG 10s 5s;
  position: absolute;
  background: tan;
  width: 0;
  height: 0; }

@-webkit-keyframes cleaningBG {
  0% {
    opacity: 0;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh; }
  10% {
    opacity: 0.7;
    left: 10vw;
    top: 0;
    width: 90vw;
    height: 100vh; }
  90% {
    opacity: 0.7;
    left: 95vw;
    top: 0;
    width: 5vw;
    height: 100vh; }
  100% {
    opacity: 0;
    left: 100vw;
    top: 0;
    width: 0;
    height: 100vh; } }

@keyframes cleaningBG {
  0% {
    opacity: 0;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh; }
  10% {
    opacity: 0.7;
    left: 10vw;
    top: 0;
    width: 90vw;
    height: 100vh; }
  90% {
    opacity: 0.7;
    left: 95vw;
    top: 0;
    width: 5vw;
    height: 100vh; }
  100% {
    opacity: 0;
    left: 100vw;
    top: 0;
    width: 0;
    height: 100vh; } }

.cleaner {
  -webkit-animation: cleaning 10s 5S forwards;
          animation: cleaning 10s 5S forwards;
  position: absolute;
  background: transparent;
  height: 100vh;
  width: 0; }

@-webkit-keyframes cleaning {
  0% {
    opacity: 0;
    left: 0;
    top: 0;
    width: 10vw; }
  10% {
    opacity: 1;
    left: 0;
    top: 0;
    width: 10vw; }
  90% {
    left: 85vw;
    top: 0;
    width: 10vw;
    height: 100vh; }
  99% {
    left: 100vw;
    top: 0;
    width: 0;
    height: 20vh;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    left: 0;
    top: 60vh;
    width: 10vw;
    height: 20vh;
    -webkit-transform: rotate(0deg) scaleX(-1);
            transform: rotate(0deg) scaleX(-1); } }

@keyframes cleaning {
  0% {
    opacity: 0;
    left: 0;
    top: 0;
    width: 10vw; }
  10% {
    opacity: 1;
    left: 0;
    top: 0;
    width: 10vw; }
  90% {
    left: 85vw;
    top: 0;
    width: 10vw;
    height: 100vh; }
  99% {
    left: 100vw;
    top: 0;
    width: 0;
    height: 20vh;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    left: 0;
    top: 60vh;
    width: 10vw;
    height: 20vh;
    -webkit-transform: rotate(0deg) scaleX(-1);
            transform: rotate(0deg) scaleX(-1); } }

.volk {
  -webkit-animation: volking 2s 1s infinite;
          animation: volking 2s 1s infinite;
  position: relative;
  width: 100%; }

@-webkit-keyframes volking {
  0% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    top: 75%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes volking {
  0% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    top: 75%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.beardToShow {
  -webkit-animation: stylish 500ms 3s forwards;
          animation: stylish 500ms 3s forwards;
  -webkit-animation-iteration-count: 4;
          animation-iteration-count: 4;
  position: fixed;
  width: 10vw;
  left: 45vw;
  top: 45vh;
  opacity: 0; }

@-webkit-keyframes stylish {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes stylish {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.loupStyle {
  -webkit-animation: fadein 10s forwards;
          animation: fadein 10s forwards;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 20vw; }

.amourForte {
  -webkit-animation: glittering 1s forwards;
          animation: glittering 1s forwards;
  -webkit-animation-iteration-count: 5;
          animation-iteration-count: 5;
  position: fixed;
  bottom: 0;
  left: 42vw;
  width: 20vw; }

