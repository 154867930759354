@mixin coords ($position, $width, $top, $left) {
  position: $position;
  width: $width;
  top: $top;
  left: $left;
}

.buttonContainer {
  @include coords(absolute, 30vw, 35vh, 35vw);
  height: 30vh;
  text-align: center;
}

.text {
  font-family: Tangerine, Helvetica, sans-serif;
  font-size: 4vw;
  text-align: center;
  color: white;
  text-shadow: 0 0 1vw greenyellow;
}

.positionStar { @include coords(fixed, 10vw, 45vh, 45vw);}

.position1 { @include coords(fixed, 15vw, 15vh, 15vw);}

.position2 { @include coords(fixed, 15vw, 55vh, 10vw);}

.position3 { @include coords(fixed, 15vw, 10vh, 30vw);}

.position4 { @include coords(fixed, 15vw, 5vh, 45vw);}

.position5 { @include coords(fixed, 15vw, 15vh, 65vw);}

.position6 { @include coords(fixed, 15vw, 35vh, 80vw);}

.position7 { @include coords(fixed, 15vw, 70vh, 70vw);}

.yellow { animation: glittering 5s ease 1s infinite }

.green { animation: glittering 3s ease 2s infinite }

.turquoise { animation: glittering 4s ease 3s infinite }

.orange { animation: glittering 5s ease 3s infinite }

.violet { animation: glittering 4s ease 1s infinite }

.pink { animation: glittering 4s ease 1s infinite }

.cherry { animation: glittering 3s ease 3s infinite }

@keyframes glittering {
  from { opacity: 100%; transform: scale(1, 1) }
  to { opacity: 90%; transform: scale(1.1, 1.1) }
}

.starExpands { animation: star 500ms 6s }
@keyframes star {
  0% { transform: scale(1, 1) rotate(0deg); }
  10% { transform: scale(1.2, 1.2) rotate(90deg); }
  50% { transform: scale(0.9, 0.9) rotate(360deg); }
  60% { transform: scale(1.1, 1.1) rotate(90deg); }
  80% { transform: scale(0.9, 0.9) rotate(180deg); }
  100% { transform: scale(1, 1) rotate(0deg); }
}

.heartsAnimated { animation: flying 7s forwards; width: 15vw }
@keyframes flying {
  0% { transform: rotate(0deg) translateX(0) rotate(0deg) scale(1, 1);  }
  100%   { transform: rotate(720deg) translateX(15vw) rotate(-720deg) scale(0.1, 0.1); top: 40vh; left: 28vw;  }
}

////////////////// 11s

.rainbow {
  animation: rainbowAppears 7s 4s forwards;
  position: fixed; top: 0; left: 0; width: 0;
}
@keyframes rainbowAppears {
  0% { opacity: 0; transform: rotate(-45deg); position: fixed; top: 100vh; left: 0; width: 0; height: 0;}
  30% { opacity: 1; transform: rotate(0deg); position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;}
  40% { opacity: 1; transform: scale(1.5,1.5); position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;}
  45% { opacity: 1; transform: scale(1,1); position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;}
  60% { opacity: 1; transform: scale(15,30); position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;}
  100% { opacity: 1; transform: rotate(180deg); position: fixed; top: 0; left: -5vw; width: 25vw; height: 25vh;}
}

////////////////// 12s

.sun1 { animation: sunPulses 8s 4s forwards; position: fixed; top: 30vw; left: 50vw; width: 0; height: 0; }
.sun2 { animation: sunRotates 8s 4s forwards; position: fixed; top: 30vw; left: 50vw; width: 0; height: 0;}
@keyframes sunRotates {
  0% { opacity: 0; transform: rotate(0deg); top: 30vw; left: 50vw; width: 0; height: 0;}
  10% { opacity: 1; transform: rotate(45deg); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  20% { opacity: 1; transform: rotate(-90deg); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  40% { opacity: 1; transform: rotate(45deg); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  60% { opacity: 1; transform: rotate(-90deg); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  70% { opacity: 1; transform: rotate(-45deg) scale(1,1); top: -15vw; left: 35vw; width: 30vw; height: 30vw;}
  90% { opacity: 0; transform: scale(3,3); top: -15vw; left: 0; width: 100vw; height: 100vh;}
  91% { opacity: 0; transform: scale(1,1); top: 0; left: 90vw; width: 10vw; height: 10vw;}
  100% { opacity: 1; transform: scale(1,1); top: 0; left: 90vw; width: 10vw; height: 10vw;}
}
@keyframes sunPulses {
  0% { opacity: 0; transform: scale(1,1); top: 30vw; left: 50vw; width: 0; height: 0;}
  10% { opacity: 1; transform: scale(0.8,0.8); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  40% { opacity: 1; transform: scale(1,1); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  45% { opacity: 1; transform: scale(0.8,0.8); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  60% { opacity: 1; transform: scale(1,1); top: 15vw; left: 35vw; width: 30vw; height: 30vw;}
  70% { opacity: 1; transform: scale(0.8,0.8); top: -15vw; left: 35vw; width: 30vw; height: 30vw;}
  90% { opacity: 0; transform: scale(3,3); top: -15vw; left: 0; width: 100vw; height: 100vh;}
  91% { opacity: 0; transform: scale(1,1); top: 0; left: 90vw; width: 10vw; height: 10vw;}
  100% { opacity: 1; transform: scale(1,1); top: 0; left: 90vw; width: 10vw; height: 10vw;}
}

////////////////// 12s

@keyframes smiles {
  0% { opacity: 0 ; transform: scale(0,0); }
  30% { opacity: 1 ; transform: scale(1,1); }
  100% { opacity: 0.2; transform: scale(0.5,0.5); }
}

////////////////// 7s

.showLove {
  top: 25vh;
  left: 25vw;
  opacity: 0;
  width: 0;
  height: 0;
  animation: emojiSwaps 5s 2s ease-in-out forwards;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes emojiSwaps {
  0% { background-image: url("../assets/img/emoji2.png"); opacity: 0 ; transform: scale(1,1); top: 25vh; left: 50vw; width: 20vw; height: 20vh}
  10% { background-image: url("../assets/img/emoji2.png"); opacity: 1 ; transform: scale(1,1); top: 25vh; left: 50vw; width: 20vw; height: 20vh }
  30% { background-image: url("../assets/img/emoji1.png"); opacity: 1 ; transform: scale(1,1) rotate(0deg); top: 25vh; left: 50vw; width: 20vw; height: 20vh}
  99% { background-image: url("../assets/img/emoji1.png"); opacity: 1 ; transform: scale(0,0) rotate(3600deg); top: 25vh; left: 50vw; width: 20vw; height: 20vh }
  100% { background-image: url("../assets/img/emoji1.png"); opacity: 0.5 ; transform: scale(0.7,0.7) rotate(0deg); top: 70vh; left: 0; width: 20vw; height: 20vh }
}

////////////////// 15s

.dirt {
  animation: cleaningBG 10s 5s;
  position: absolute;
  background: tan;
  width: 0;
  height: 0;
}
@keyframes cleaningBG {
  0% { opacity: 0; left: 0; top: 0; width: 100vw;  height: 100vh; }
  10% { opacity: 0.7; left: 10vw; top: 0; width: 90vw;  height: 100vh; }
  90% { opacity: 0.7;  left: 95vw; top: 0; width: 5vw;  height: 100vh; }
  100% { opacity: 0;  left: 100vw; top: 0; width: 0;  height: 100vh; }
}

.cleaner {
  animation: cleaning 10s 5S forwards;
  position: absolute;
  background: transparent;
  height: 100vh;
  width: 0;
}
@keyframes cleaning {
  0% { opacity: 0; left: 0; top: 0; width: 10vw;}
  10% { opacity: 1; left: 0; top: 0; width: 10vw;}
  90% { left: 85vw; top: 0; width: 10vw; height: 100vh;}
  99% { left: 100vw; top: 0; width: 0; height: 20vh; transform: rotate(0deg);}
  100% { left: 0; top: 60vh; width: 10vw; height: 20vh; transform: rotate(0deg) scaleX(-1);}
}

.volk {
  animation: volking 2s 1s infinite;
  position: relative;
  width: 100%;
}
@keyframes volking {
  0% { top: 0; transform: rotate(0deg)  }
  50% { top: 75%; transform: rotate(90deg)}
  100% { top: 0; transform: rotate(0deg) }
}

////////////////// 10s

.beardToShow {
  animation: stylish 500ms 3s forwards;
  animation-iteration-count: 4;
  position: fixed;
  width: 10vw;
  left: 45vw;
  top: 45vh;
  opacity: 0;
}
@keyframes stylish {
  0% { opacity: 0; }
  30% { opacity: 1; }
  100% { opacity: 0; }
}

.loupStyle {
  animation: fadein 10s forwards;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 20vw;
}

////////////////// 5s

.amourForte {
  animation: glittering 1s forwards;
  animation-iteration-count: 5;
  position: fixed;
  bottom: 0;
  left: 42vw;
  width: 20vw;
}